<template>
  <v-container fluid class="down-top-padding">
    <BaseBreadcrumb
      :title="page.title"
      :breadcrumbs="breadcrumbs"
    ></BaseBreadcrumb>

    <v-card class="mt-4" v-for="card in availableCreditCards" :key="card.id">
      <v-btn
        v-if="card.id === defaultCreditCard"
        class="ma-2"
        fab
        dark
        small
        color="primary"
      >
        <v-icon dark>mdi-star</v-icon>
      </v-btn>
      <v-card-title class="text-h6">{{ card.cardHolderName }}</v-card-title>
      <v-card-text>
        <span class="text-body-1">{{ card.creditCardType }}</span>
        <v-chip class="text-body-1 ml-1"
          >**** **** **** {{ card.lastFourDigits }}</v-chip
        >
        <v-btn
          color="primary"
          text
          @click="deleteCreditCard(card.id)"
          :disabled="loading"
          >Eliminar</v-btn
        >
        <v-btn
          v-if="card.id != defaultCreditCard"
          color="primary"
          text
          @click="setDefaultCreditCard(card.id)"
          :disabled="loading"
          >Tarjeta por defecto</v-btn
        >
      </v-card-text>
    </v-card>

    <v-card class="mt-4">
      <v-card-title class="text-h6">Añadir nueva tarjeta</v-card-title>
      <v-form class="pa-2">
        <v-col lg="7" sm="10" xl="4" xs="12">
          <v-text-field
            v-model="creditCardHolderName"
            label="Titular"
            outlined
            dense
          ></v-text-field>
          <v-card id="card-element" outlined class="pa-4"></v-card>
          <p class="text-body-2 mt-2" role="alert">{{ creditCardError }}</p>
          <v-row>
            <v-btn
              class="ml-3"
              color="primary"
              @click="addCreditCard"
              :disabled="
                loading ||
                  !creditCardCompleted ||
                  !!creditCardError ||
                  !creditCardHolderName
              "
              >Añadir</v-btn
            >
          </v-row>
        </v-col>
      </v-form>
    </v-card>
  </v-container>
</template>

<script>
import Axios from "axios";
import settings from "../../settings";

export default {
  name: "PaymentMethods",
  data: () => ({
    page: { title: "Métodos de pago" },
    breadcrumbs: [
      {
        text: "Mi Perfil",
        to: { name: "Profile" },
        disabled: false,
        link: true,
        exact: true
      },
      {
        text: "Métodos de pago"
      }
    ],
    availableCreditCards: [],
    defaultCreditCard: null,
    loading: true,
    creditCardHolderName: null,
    stripe: null,
    creditCardElement: null,
    creditCardError: null,
    creditCardCompleted: false
  }),
  methods: {
    async updateCreditCardList() {
      let response = await Axios.get("purchase/creditCards");
      this.availableCreditCards = response.data ?? [];
      response = await Axios.get("purchase/creditCards/default");
      this.defaultCreditCard = response.data?.id;
      this.creditCardElement.clear();
    },
    async addCreditCard() {
      this.loading = true;
      const response = await Axios.get("purchase/setupCreditCardSecret");
      const clientSecret = response.data;
      const result = await this.stripe.confirmCardSetup(clientSecret, {
        payment_method: {
          card: this.creditCardElement,
          billing_details: {
            name: this.creditCardHolderName
          }
        }
      });
      if (result.error) {
        this.creditCardError = result.error.message;
      } else {
        await this.updateCreditCardList();
      }
      this.loading = false;
    },
    async deleteCreditCard(id) {
      this.loading = true;
      await Axios.delete(`purchase/creditCards/${id}`);
      await this.updateCreditCardList();
      this.loading = false;
    },
    async setDefaultCreditCard(id) {
      this.loading = true;
      await Axios.put(`purchase/creditCards/default`, { id });
      await this.updateCreditCardList();
      this.loading = false;
    }
  },
  async created() {
    await this.updateCreditCardList();
    this.loading = false;
  },
  async mounted() {
    this.stripe = window.Stripe(settings.stripeKey);
    const elements = this.stripe.elements();
    const card = elements.create("card");
    card.mount("#card-element");
    card.on("change", ({ complete, error }) => {
      this.creditCardError = error ? error.message : null;
      this.creditCardCompleted = complete;
    });
    this.creditCardElement = card;
  }
};
</script>

<style></style>
